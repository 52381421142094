// Variable overrides
$sidebar-width: 200px;
$body-bg: #f8f8f8;
$body-color: #000;

$theme-colors: (
  white: #fff,
  black: #000,
  // primary: #1870ba,
  primary: #168FBF,//#00985C,
  secondary: #a8cddb, //#E5F4ED,
  danger: #ba1922,
  success: #00b7ff,//#19ba41,
  // danger: #ff1e27,
  custom-color: #900,
  light-red: #e3bec0,
  grey: #d5d7d8,
  light-grey: #f4f4f4,
  dark-grey: #464646,
  blue: #168FBF,
  light-blue: #168FBF80,
  lighter-blue: #168FBF20,
  green: #00ae37,
  orange: #ff9e00,

);
