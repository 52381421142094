.loginPage {
  .container {
    background-color: #f4f4f4;
  }
  .loginContainer {
    max-width: 550px;
    margin: auto;
    border: none;
  }
  .header {
    padding: 3.75rem 0;
    border: none;
  }
  .card {
    border: none;
    border-radius: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }
  .formContainer {
    padding: 0 5.125rem;
  }
  .formFooter {
    padding: 0.5rem 0 3.75rem;
    display: inline-flex;
    justify-content: space-around;
    flex-direction: column;
  }
}
